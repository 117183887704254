/* eslint-disable react/no-danger */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';
import SignatureCanvas from 'react-signature-canvas';
import {
  Image, Loading, Typography
} from 'tfc-components';

import bgConfirm from 'assets/images/bg-confirm.jpg';
import xacnhan from 'assets/images/xacnhan.png';
import Icon from 'components/atoms/Icon';
import { UserInfoType } from 'pages/UserInfo';
import { getGenerakService } from 'services/checkin';
import { CheckinParams } from 'services/checkin/types';
import mapModifiers, {
  formatDateDDMMYYYY, imageLoader, resizeImage, wrapText
} from 'utils/functions';

type ConfirmProps = {
  checkinMethod: UseFormReturn<UserInfoType>;
  handleSubmit: (data: CheckinParams) => void;
  isCheckin?: boolean;
  loading?: boolean;
};

const Confirm: React.FC<ConfirmProps> = ({
  handleSubmit, checkinMethod, isCheckin, loading
}) => {
  const WIDTH = 1600;
  const HEIGHT = 2134;
  const TEXT_SIZE = 36;
  const TEXT_SIZE_NAME = 40;
  const TEXT_COLOR = '#fff';
  const [background, setBackground] = useState<HTMLImageElement>();
  const [signature, setSignature] = useState<SignatureCanvas>();
  const [url, setUrl] = useState<string>('');
  const [confirmJoin, setConfirmJoin] = useState<boolean>(true);
  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const { data: general } = useQuery('general', getGenerakService);
  const canvas = useMemo(() => {
    const c = document.createElement('canvas');
    c.width = WIDTH;
    c.height = HEIGHT;

    return c;
  }, []);
  const onhandleSubmit = () => {
    if (!confirmJoin || !isSigned) {
      setFirstTime(true);
      return;
    }
    canvas.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], 'confirm.jpeg', { type: 'image/jpeg' });
        await resizeImage(file, (val) => {
          const submitData = checkinMethod.getValues();
          handleSubmit({
            invitation_code: submitData.invitation_code || '',
            phone: submitData.phone,
            full_name: submitData.full_name,
            position_or_degree: submitData.position_or_degree,
            hospital: submitData.hospital,
            department: submitData.department,
            provinceCode: submitData.provinceCode?.value,
            dob: submitData.dob ? formatDateDDMMYYYY(submitData.dob) : undefined,
            signature: val,
          });
        });
      }
    });
  };

  const handleClearSignature = () => {
    setIsSigned(false);
    signature?.clear();
    setUrl('');
  };

  const handleEndSignature = () => {
    setIsSigned(true);
    setUrl(signature?.getTrimmedCanvas().toDataURL('image/png') || '');
  };

  const ctx = useMemo(() => canvas.getContext('2d')!, [canvas]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const bg = await imageLoader(bgConfirm);

      if (mounted) setBackground(bg);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  const draw = useCallback(async () => {
    if (!background || !general) {
      return;
    }

    ctx.drawImage(background, 0, 0, WIDTH, HEIGHT);

    ctx.font = `bold ${TEXT_SIZE}px Brandon Text F`;

    ctx.fillStyle = TEXT_COLOR;
    const title = general.seminar.split('<br />');
    title.forEach((item: string, index: number) => {
      ctx.fillText(item.toUpperCase(), 455, 490 + index * 50);
    });
    ctx.fillText(`Thời gian: ${general.seminarStartAt}`, 585, 646);
    ctx.fillText(`Địa điểm: ${general.seminarIn.toUpperCase()}`, 650, 705);

    ctx.font = `bold ${TEXT_SIZE_NAME}px Brandon Text F`;

    ctx.fillText((checkinMethod.getValues('full_name') || '').toUpperCase(), 460, 802);
    const wrappedText = wrapText(ctx, (checkinMethod.getValues('hospital') || '').toUpperCase(), 462, 865, WIDTH - 630, 56);
    wrappedText.forEach((item: any) => {
      ctx.fillText(item[0], item[1], item[2]);
    });
    ctx.fillText(`TỈNH THÀNH: ${(checkinMethod.getValues('provinceCode.label') || '').toUpperCase()}`, 236, 932);

    if (url) {
      const sign = await imageLoader(url);

      ctx.drawImage(
        sign,
        sign.width > 1000 ? 290 : 290 + (1000 - sign.width) / 2,
        sign.height > 450 ? 1200 : 1200 + (450 - sign.height) / 2,
        sign.width > 1000 ? 1000 : sign.width,
        sign.height > 450 ? 450 : sign.height
      );
    }
  }, [background, checkinMethod, ctx, general, url]);

  useEffect(() => {
    draw();
  }, [draw]);

  return (
    <div className="p-confirm">
      <div className="p-confirm_conventionTitle">
        <div className="p-confirm_title">
          <Typography.Text
            extendClasses="fs-26x36"
            fontweight="600"
          >
            Hội nghị:
          </Typography.Text>
          <Typography.Text
            fontweight="600"
          >
            <span dangerouslySetInnerHTML={{ __html: general?.seminar || '' }} />
          </Typography.Text>
        </div>
        <Typography.Text
          extendClasses="fs-26x36"
          fontweight="600"
        >
          {`Thời gian: ${general?.seminarStartAt || ''}`}
        </Typography.Text>
        <Typography.Text
          extendClasses="fs-26x36"
          fontweight="600"
        >
          {`Địa điểm: ${general?.seminarIn || ''}`}
        </Typography.Text>
      </div>
      <div className="p-confirm_userInfo">
        <Typography.Text
          extendClasses="fs-30x52"
          fontweight="600"
        >
          HỌ VÀ TÊN:
          {' '}
          {checkinMethod.getValues('full_name') || ''}
        </Typography.Text>
        <Typography.Text
          extendClasses="fs-30x52"
          fontweight="600"
        >
          BỆNH VIỆN:
          {' '}
          {checkinMethod.getValues('hospital') || ''}
        </Typography.Text>
        <Typography.Text
          extendClasses="fs-30x52"
          fontweight="600"
        >
          TỈNH THÀNH:
          {' '}
          {checkinMethod.getValues('provinceCode').label || ''}
        </Typography.Text>
      </div>
      <div className="p-confirm_conditionWrapper">
        <div className="p-confirm_conditionWrap">
          <div
            className="p-confirm_condition"
            onClick={() => setConfirmJoin(!confirmJoin)}
          >
            {confirmJoin && <Icon iconName="check" size="38" />}
          </div>
          <p
            onClick={() => setConfirmJoin(!confirmJoin)}
            className="p-confirm_termsText fs-26x36 color-antiFlashWhite"
          >
            Xác nhận tham dự hội nghị.
          </p>
        </div>
        {!confirmJoin && firstTime && (
          <Typography.Text
            extendClasses="p-confirm_termsText-error fs-18x26 u-mt-8"
            fontweight="400"
          >
            Vui lòng xác nhận để tiếp tục
          </Typography.Text>
        )}
      </div>
      <div className="p-confirm_signatureWrapper">
        <SignatureCanvas
          canvasProps={{ className: 'sigCanvas' }}
          ref={(data: any) => setSignature(data)}
          onEnd={handleEndSignature}
        />
        <div
          className="p-confirm_clear"
          onClick={handleClearSignature}
        >
          <Icon iconName="clear" size="38" />
        </div>
        {!isSigned && firstTime && (
          <Typography.Text
            extendClasses="p-confirm_termsText-error fs-18x26 u-mt-8"
            fontweight="400"
          >
            Vui lòng ký tên để tiếp tục
          </Typography.Text>
        )}
      </div>
      <div
        className={mapModifiers('p-confirm_confirmButton', loading && 'loading')}
        onClick={() => {
          if (!loading) {
            onhandleSubmit();
          }
        }}
      >
        {isCheckin && (
          <Typography.Text
            extendClasses="p-confirm_termsText-error p-confirm_termsText-checkin fs-26x36 u-mt-8"
            textStyle="center"
            fontweight="400"
          >
            Đã đăng ký tham dự chương trình
          </Typography.Text>
        )}
        <Image
          imgSrc={xacnhan}
          alt="checkBtn"
          ratio={600 / 109}
          extendClasses="p-confirm_confirmButton_img"
        />
        {loading && (
          <Loading.Circle color="#0e3878" width={36} extendClasses="p-confirm_confirmButton_indicator" />
        )}
      </div>
    </div>
  );
};

export default Confirm;
