/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Typography } from 'tfc-components';

import MainLayout from 'components/templates/MainLayout';
import Confirm from 'pages/Confirm';
import Congratulation from 'pages/Congratulation';
import Register, { CheckInvitationType } from 'pages/Register';
import UserInfo, { UserInfoType } from 'pages/UserInfo';
import { checkInvitationService, checkinService } from 'services/checkin';
import {
  CheckInvitationData,
  CheckInvitationParams,
  CheckinParams,
} from 'services/checkin/types';
import returnValidateErrorMessages from 'services/common/errors';
import useSchemas from 'utils/shemas';

const fieldToText = (field: string) => {
  switch (field) {
    case 'invitation_code':
      return 'Mã CME';
    case 'phone':
      return 'Số điện thoại';
    case 'full_name':
      return 'Họ và tên';
    case 'position_or_degree':
      return 'Vị trí / bằng cấp';
    case 'hospital':
      return 'Bệnh viện';
    case 'department':
      return 'Phòng ban';
    case 'provinceCode':
      return 'Tỉnh/Thành phố';
    default:
      return '';
  }
};

const Home: React.FC = () => {
  const { userInfoSchema } = useSchemas();
  const checkMethod = useForm<CheckInvitationType>({
    mode: 'onChange',
    defaultValues: {
      invitation_code: '',
      phone: '',
    },
  });
  const checkinMethod = useForm<UserInfoType>({
    mode: 'onChange',
    resolver: yupResolver(userInfoSchema),
    defaultValues: {
      invitation_code: '',
      full_name: '',
      phone: '',
      hospital: '',
      department: '',
      provinceCode: undefined,
      isRequireCme: true,
    },
  });

  /* States */
  const [step, setStep] = React.useState<number>(0);
  const [errorText, setErrorText] = React.useState<string>('');
  const [isCheckin, setIsCheckin] = React.useState<boolean>(false);
  const [isTemporary, setIsTemporary] = React.useState<boolean>(false);

  const { mutate: checkInvitationMutate } = useMutation(
    ['checkInvitationMutate'],
    async (data: CheckInvitationParams) => checkInvitationService({
      invitation_code: data.invitation_code ?? '',
      phone: data.phone ?? '',
    }),
    {
      onSuccess: (data: CheckInvitationData) => {
        setStep(1);
        checkinMethod.setError('invitation_code', {});
        if (data) {
          checkinMethod.reset({
            invitation_code: data?.code,
            full_name: data?.fullName,
            phone: data?.phone,
            position_or_degree: data?.positionOrDegree,
            hospital: data?.hospital,
            department: data?.department,
            provinceCode: {
              label: data?.province?.name,
              value: data?.province?.code,
            },
            dob: data?.dob,
            isRequireCme: true,
          });
        } else {
          checkinMethod.reset({});
        }
      },
      onError: (error: any) => {
        if (error?.length > 0) {
          error.forEach((err: any) => {
            if (err.code === 'existed') {
              setErrorText('Đã đăng ký tham dự chương trình');
            }
          });
        } else if (error?.response?.status === 404) {
          setErrorText('Thông tin không đúng');
        } else {
          setErrorText('Đã có lỗi xảy ra, hãy thử lại !');
        }
      },
    }
  );

  const { mutate: checkinMutate, isLoading } = useMutation(
    ['checkinMutate'],
    async (data: CheckinParams) => checkinService(data),
    {
      onSuccess: () => {
        setStep(3);
      },
      onError(error: any) {
        if (error.length > 0) {
          if (error[0]?.code === 'existed') {
            setIsCheckin(true);
            return;
          }
          error.forEach((err: ValidateError) => {
            checkinMethod.setError(err.field, {
              message: returnValidateErrorMessages(err, fieldToText(err.field)),
            });
            const confirmErr = ['signature'];
            if (!confirmErr.includes(err.field)) {
              setStep(1);
            }
          });
        }
      },
    }
  );

  const handleCheck = (data: CheckInvitationType) => {
    if (
      !checkMethod.getValues('invitation_code')
      && !checkMethod.getValues('phone')
    ) {
      checkMethod.setError('invitation_code', {
        message: 'Vui lòng cung cấp thông tin',
      });
      checkMethod.setError('phone', { message: 'Vui lòng cung cấp thông tin' });
    } else {
      checkInvitationMutate({
        invitation_code: data.invitation_code,
        phone: data.phone,
      });
    }
  };

  const handleRegister = () => {
    checkinMethod.setError('invitation_code', {});
    checkinMethod.setValue('invitation_code', '');
    checkinMethod.setValue('phone', checkMethod.getValues('phone') ?? '');
    checkinMethod.setValue('isRequireCme', false);
    setIsTemporary(true);
    setStep(1);
  };

  const handleConfirm = () => {
    setStep(2);
  };

  const handleComplete = () => {
    window.location.reload();
  };

  const handleSubmit = (data: CheckinParams) => {
    checkinMutate(data);
  };

  return (
    <MainLayout>
      <Typography.Heading
        textStyle="center"
        fontweight="600"
        extendClasses="t-mainLayout_subHeading fs-36x44"
      >
        {step === 2 ? 'XÁC NHẬN' : 'BIỂU MẪU'}
        {' '}
        ĐĂNG KÍ THAM DỰ
        <br />
        CHƯƠNG TRÌNH
      </Typography.Heading>
      <div className="p-register">
        {step === 0 && (
          <Typography.Text
            textStyle="italic"
            extendClasses="fs-26x36 p-register_confirmText"
          >
            Vui lòng nhập thông tin bên dưới để đăng kí tham dự chương trình
          </Typography.Text>
        )}
        {step === 1 && (
          <Typography.Text
            textStyle="italic"
            extendClasses="fs-26x36 p-register_confirmText"
          >
            {`Vui lòng ${isTemporary ? 'điền' : 'kiểm tra'
              } thông tin bên dưới và xác nhận`}
          </Typography.Text>
        )}
        {(() => {
          switch (step) {
            case 0:
              return (
                <Register
                  method={checkMethod}
                  handleCheck={handleCheck}
                  errorText={
                    checkinMethod.formState.errors.invitation_code?.message
                  }
                  handleRegister={handleRegister}
                  errorTextType={errorText}
                />
              );
            case 1:
              return (
                <UserInfo
                  method={checkinMethod}
                  handleConfirm={handleConfirm}
                  isTemporary={isTemporary}
                />
              );
            case 2:
              return (
                <Confirm
                  checkinMethod={checkinMethod}
                  handleSubmit={handleSubmit}
                  isCheckin={isCheckin}
                  loading={isLoading}
                />
              );
            case 3:
              return <Congratulation handleComplete={handleComplete} />;
            default:
              return null;
          }
        })()}
      </div>
    </MainLayout>
  );
};

export default Home;
